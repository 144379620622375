import { useRouter } from "@app-routes";
import { KnowledgebaseQuestionPreviewFragment } from "@gql-schema";
import { useMapFaqs } from "@landing-page-zone/hooks/useMapFaqs";
import { FaqSection } from "@ui/components/FaqSection/FaqSection";
import { FC, PropsWithChildren, ReactNode, memo } from "react";

interface FaqSectionComponentProps {
    invert?: boolean;
    questions?: KnowledgebaseQuestionPreviewFragment[];
    title?: ReactNode;
}

const FaqByTagSectionComponent: FC<PropsWithChildren<FaqSectionComponentProps>> = (props) => {
    const { invert, questions, title } = props;
    const { push } = useRouter();

    const faqData = useMapFaqs(questions ?? [], invert);

    if (questions?.length === 0) {
        return null;
    }

    return (
        <FaqSection
            data={faqData}
            onClickRedirect={(item) => {
                push("knowledgebase-zone/detail", {
                    articleId: item.id,
                    slug: item.seoName,
                });
            }}
            invert={invert}
            title={title}
        />
    );
};

export const FaqByTagSection = memo(FaqByTagSectionComponent);

FaqByTagSection.displayName = "FaqByTagSection";
