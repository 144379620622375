import { NextPage } from "@app-routes";
import { BlogBox } from "@blog-zone/components/BlogBox/BlogBox";
import { QuestionType, SubVertical, Vertical } from "@gql-schema";
import { useGetBlogArticlesQuery } from "@gql-schema/BlogQueries";
import { useGetKnowledgebaseQuestionsByTagsQuery } from "@gql-schema/KnowledgebaseQueries";
import { useNavbarConfigQuery } from "@gql-schema/NavbarQueries";
import { ApolloQueryHelper } from "@gql-schema/apollo-query-helper";
import { FaqByTagSection } from "@shared/components/FaqByTagSection/FaqByTagSection";
import { HomepageCarousel } from "@shared/components/HomepageCarousel/HomepageCarousel";
import { LandingSuperHeroSection } from "@shared/components/LandingSuperHeroSection/LandingSuperHeroSection";
import { LandingWatchDogBanner } from "@shared/components/LandingWatchDogBanner/LandingWatchDogBanner";
import { Layout } from "@shared/components/Layout/Layout";
import { GreenNavBar } from "@shared/components/Navigation/GreenNavBar";
import { addApolloState, initializeApollo } from "@shared/lib/Apollo/apolloClient";
import { basicStaticProps } from "@shared/utils/basic-static-props";
import { GetStaticProps } from "next";

const currentYear = new Date().getFullYear();

const Index: NextPage<"index"> = () => {
    const { data: navbarData } = useNavbarConfigQuery();
    const { data: blogData } = useGetBlogArticlesQuery({ variables: { limit: 3, offset: 0 } });
    const { data: questionData } = useGetKnowledgebaseQuestionsByTagsQuery({
        variables: { data: { tags: [13], type: QuestionType.Question } },
    });

    if (!navbarData || !blogData || !questionData) {
        return null; // TODO @vejvis
    }

    return (
        <Layout
            title="Férové ceny elektřiny, plynu a pojištění"
            metaTagData={{
                metaTitle: `Elektřina, plyn a pojištění. Ceny ${currentYear}`,
                metaDescription: `My počítáme, vy šetříte. Nejvýhodnější ceny energií a pojištění ${currentYear}. Nezávislé srovnání online. Bez otravných telefonátů. Zdarma a bez rizika.`,
            }}
            vertical={Vertical.General}
            subVertical={SubVertical.General}
        >
            <GreenNavBar absolute navbarData={navbarData.navbarConfig} />
            <LandingSuperHeroSection />
            {/*<LandingSuperHeroBoxes $top={-120} />*/}
            <HomepageCarousel /* className="-mt-[120px]" */ />
            <LandingWatchDogBanner />
            <FaqByTagSection questions={questionData.getKnowledgebaseQuestionsByTags} />
            {/*<TestimonialsCarousel />*/}
            <BlogBox
                $mt={[null, null, -62, -74]} // remove this negative margin if testimonials will be back
                blogArticles={blogData.blogArticles}
            />
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = basicStaticProps(async () => {
    const apolloClient = initializeApollo(null);

    await ApolloQueryHelper.navbarConfig(apolloClient);
    await ApolloQueryHelper.blogGetArticles(apolloClient, 3, 0);
    await ApolloQueryHelper.knowledgebaseGetQuestionsByTags(apolloClient, [13], QuestionType.Question);

    return addApolloState(apolloClient, {
        props: {},
        revalidate: 10, // In seconds
    });
});

export default Index;
