import { AnalyticsService } from "@shared/services/AnalyticsService";
import { BulletList, BulletListItem } from "@ui/components/BulletList/BulletList";
import { ButtonGreenPrimary } from "@ui/components/Button/ButtonGreenPrimary";
import { Wave } from "@ui/components/Wave/Wave";
import { Wolf } from "@ui/components/Wolf/Wolf";
import { BUTTONS } from "@ui/theme/buttons";
import { memo } from "react";

const WATCHWOLF_ANALYTICS_CALLBACK = AnalyticsService.uiEvent("Button", "Click", "HP_HlidaciVlk");

interface Props {
    className?: string;
}

export const LandingWatchDogBanner = memo<Props>((props) => {
    const buttonComponent = (
        <ButtonGreenPrimary
            $height={[BUTTONS.medium, null, BUTTONS.default]}
            $px={[32, 48, null, 32, 48]}
            analyticsCallback={WATCHWOLF_ANALYTICS_CALLBACK}
            route="watch-wolf-zone/entry-page"
        >
            Nastavit hlídacího vlka
        </ButtonGreenPrimary>
    );

    return (
        <div className={`"overflow-hidden ${props.className ?? ""}`}>
            <Wave color="yellow" type="top" />
            <div className="bg-yellow text-green">
                <div className="container relative flex grid-cols-3 flex-col pb-7 pt-8 max-md:px-6 md:py-12 lg:grid lg:items-center lg:pb-18 lg:pt-[120px]">
                    <div>
                        <h2 className="ui-typo-h2 mb-8">Hlídací vlk hlídá smlouvy za&nbsp;vás</h2>
                        <div className="ui-typo-body mb-8 md:mb-10">
                            S&nbsp;naším Hlídacím vlkem už&nbsp;žádné termíny nepropásnete! Zase jste zapomněli,
                            kdy&nbsp;vám&nbsp;končí smlouva s&nbsp;dodavatelem elektřiny a&nbsp;plynu? Nemůžete najít
                            smlouvu na&nbsp;pojištění auta a&nbsp;netušíte, do&nbsp;kdy&nbsp;můžete vypovědět povinné
                            ručení, nebo havarijní pojištění? Pusťte to&nbsp;z hlavy - pohlídáme vše&nbsp;za&nbsp;vás.
                        </div>
                        <div className="mt-10 max-lg:hidden">{buttonComponent}</div>
                    </div>
                    <div className="-order-1 mb-8 px-6 lg:-order-none lg:mt-0">
                        <Wolf className="mx-auto w-full max-w-[226px] sm:max-w-[320px]" name="sitting" />
                    </div>
                    <div className="mt-8 lg:mt-0">
                        <BulletList className="space-y-4 text-14 font-bold leading-[21px] lg:text-20 lg:leading-[31px]">
                            <BulletListItem>
                                včas upozorníme na&nbsp;termíny konce úvazků s&nbsp;dostatečným předstihem
                            </BulletListItem>
                            <BulletListItem>doporučíme nejvýhodnější nabídky</BulletListItem>
                            <BulletListItem>
                                vše&nbsp;na&nbsp;jednom místě, nezávazně a&nbsp;bez&nbsp;rizika
                            </BulletListItem>
                            <BulletListItem>nejsme na&nbsp;jedno použití, naše služby jsou dlouhodobé</BulletListItem>
                        </BulletList>
                    </div>
                    <div className="mt-8 flex justify-center lg:hidden">{buttonComponent}</div>
                </div>
            </div>
            <Wave color="yellow" type="bottom" />
        </div>
    );
});

LandingWatchDogBanner.displayName = "LandingWatchDogBanner";
