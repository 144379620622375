import { IconsSet } from "@config/icons";
import { Icon } from "@ui/components/Icon/Icon";
import { Markdown } from "@ui/components/Markdown/Markdown";
import { ColorProperty } from "@uxf/styles/types";
import { CSSProperties, Children, FC, PropsWithChildren, ReactElement, cloneElement, isValidElement } from "react";

export interface BulletListItemProps {
    bulletColor?: ColorProperty;
    className?: string;
    contentElement?: "div" | "p";
    hideIcon?: boolean;
    iconName?: IconsSet;
    iconSize?: number;
    markdown?: boolean;
    style?: CSSProperties;
}

export const BulletListItem: FC<PropsWithChildren<BulletListItemProps>> = (props) => {
    const ContentElement = props.contentElement ?? "p";

    return (
        <div className={`ui-bullet-list-item ${props.className ?? ""}`} role="listitem" style={props.style}>
            {!props.hideIcon && (
                <div className="min-w-0 shrink-0 items-center">
                    <Icon
                        className="text-[var(--bullet-color)]"
                        name={props.iconName ?? "bullets"}
                        size={props.iconSize ?? 14}
                        style={
                            {
                                "--bullet-color": props.bulletColor ?? "inherit",
                            } as CSSProperties
                        }
                    />
                </div>
            )}
            <div className="ml-2 min-w-0 grow">
                {props.markdown && typeof props.children === "string" ? (
                    <Markdown
                        className="[&_p]:text-inherit] [&_p]:leading-inherit [&_p]:font-inherit"
                        source={props.children}
                    />
                ) : (
                    <ContentElement>{props.children}</ContentElement>
                )}
            </div>
        </div>
    );
};

// const BULLET_LIST_ITEM_TYPE = (<BulletListItem />).type;

type Props = BulletListItemProps;

export const BulletList: FC<PropsWithChildren<Props>> = (props) => {
    const _children = Children.map(props.children, (child) => {
        if (child === false) {
            return null;
        }
        if (isValidElement(child)) {
            return cloneElement<BulletListItemProps>(child as ReactElement<BulletListItemProps>, {
                className: child.props.className,
                bulletColor: child.props.bulletColor ?? props.bulletColor,
                contentElement: child.props.contentElement ?? props.contentElement,
                iconName: child.props.iconName ?? props.iconName,
                iconSize: child.props.iconSize ?? props.iconSize,
                markdown: child.props.markdown ?? props.markdown,
            });
        }
        // TODO: @KubaH @Vikus - nefunguje správně ve všech případech
        //throw Error("BulletList: BulletListItem components are only allowed children");
    });

    return (
        <div className={`ui-bullet-list ${props.className ?? ""}`} role="list" style={props.style}>
            {_children}
        </div>
    );
};
