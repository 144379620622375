import { Box } from "@ui/components/Box/Box";
import { FaqAccordion, FaqAccordionItemData } from "@ui/components/FaqAccordion/FaqAccordion";
import { TypoH2 } from "@ui/components/Typography/TypoH2";
import { COLORS } from "@ui/theme/colors";
import { CONTAINERS } from "@ui/theme/containers";
import { FC, PropsWithChildren, ReactNode, memo } from "react";

interface Props {
    title?: ReactNode;
    data: FaqAccordionItemData[];
    invert?: boolean;
    onClickRedirect?: (item: FaqAccordionItemData) => void;
}

const DEFAULT_COLORS = {
    background: COLORS.white,
    title: COLORS.green.default,
};

const INVERTED_COLORS = {
    background: COLORS.green.default,
    title: COLORS.yellow.default,
};

const Component: FC<PropsWithChildren<Props>> = (props) => {
    const { data, title = "Časté dotazy", onClickRedirect, invert } = props;

    if (data.length === 0) {
        return null;
    }

    const colors = invert ? INVERTED_COLORS : DEFAULT_COLORS;

    return (
        <Box $backgroundColor={colors.background}>
            <Box
                as="section"
                $maxWidth={Object.values(CONTAINERS)}
                $mx="auto"
                $px={[24, null, 0]}
                $pt={[36, null, 44, 68]}
                $pb={[32, null, 56, 128]}
            >
                <TypoH2 $color={colors.title} $textAlign="center">
                    {title}
                </TypoH2>
                <FaqAccordion $mt={[36, null, 68, 80]} onClickRedirect={onClickRedirect} data={data} invert={invert} />
            </Box>
        </Box>
    );
};

export const FaqSection = memo(Component);

FaqSection.displayName = "FaqSection";
